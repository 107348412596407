import React from 'react';
import styles from './Videos.module.css';
import { PageHeader } from '../../components/PageHeader/PageHeader';

type Props = {};
type State = {};
export default class Videos extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <div className={styles.container}>
                <PageHeader image={require('../../assets/images/gf_2.jpg')} photographer={"Ashley Price"}>
                    <h1>Videos</h1>
                </PageHeader>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.item}>
                            <h1>Coyote</h1>
                            <h3>Released October 2020</h3>
                            <iframe className={styles.iframe} src="https://www.youtube.com/embed/4ZptqNsOZW4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className={styles.item}>
                            <h1>Necessary Feeling</h1>
                            <h3>Released March 2018</h3>
                            <iframe className={styles.iframe} src="https://www.youtube.com/embed/z-ofEG6uYGU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.item}>
                            <h1>Surface Tension</h1>
                            <h3>Released December 2017</h3>
                            <iframe className={styles.iframe} src="https://www.youtube.com/embed/NlYK0I3qIWA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className={styles.item}>
                            <h1>Business</h1>
                            <h3>Released July 2015</h3>
                            <iframe className={styles.iframe} src="https://www.youtube.com/embed/m4OnRL5LbJQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.item}>
                            <h1>Holdin' On</h1>
                            <h3>Released July 2015</h3>
                            <iframe className={styles.iframe} src="https://www.youtube.com/embed/-mZpjPvro8c" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className={styles.item}>
                            <h1>Business - Shiner Van Sessions</h1>
                            <h3>Released November 2014</h3>
                            <iframe className={styles.iframe} src="https://www.youtube.com/embed/FZVleOs6RXY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>

                    <div className={styles.row}>
                        <div className={styles.item}>
                            <h1>These Dreams</h1>
                            <h3>Released May 2013</h3>
                            <iframe className={styles.iframe} src="https://www.youtube.com/embed/_H1mKYoN1JM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}