import React from 'react';
import styles from './PageHeaderFull.module.css';

type Props = {
    image: string;
    children?: any;
    photographer?: string;
}
export const PageHeaderFull: React.FC<Props> = (props: Props) => {
    const headerStyle = { backgroundImage: `url('${props.image}')` };
    return (
        <div style={headerStyle} className={styles.container}>
            {props.children}
            {props.photographer && <div className={styles.photographer}>
                Photographer: {props.photographer}
            </div>}
        </div>
    )
}