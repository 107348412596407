import React from 'react';
import styles from './ShowsDesktop.module.css';
import { Show } from '../interfaces';
import { Table } from 'antd';

type Props = {
    shows: Show[];
};
type State = {};
export default class ShowsDesktop extends React.Component<Props, State> {

    render() {

        const dataSource = this.props.shows.map((show, i) => {
            return {
                key: i,
                date: show.date,
                venue: show.venue,
                city: show.city,
                time: show.time,
                details: show.details || '',
            }
        });

        const columns = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Venue',
                dataIndex: 'venue',
                key: 'venue',
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Time',
                dataIndex: 'time',
                key: 'time',
            },
            {
                title: '',
                dataIndex: 'details',
                key: 'detail',
            },
        ]

        return (
            <div className={styles.container}>
                <Table dataSource={dataSource} columns={columns} />
            </div>
        )
    }
}