import { Icon } from 'antd';
import moment from 'moment';
import React from 'react';
import { GFButton } from '../../components/Button/GFButton';
import { PageHeaderFull } from '../../components/PageHeaderFull/PageHeaderFull';
import { Show } from '../Shows/interfaces';
import styles from './Home.module.css';

type Props = {
    shows: Show[];
};
type State = {};

export default class Home extends React.Component<Props, State> {

    private contentRef: React.RefObject<any>;

    constructor(props: Props) {
        super(props);
        this.contentRef = React.createRef();
    }

    private scrollToContent(offset: number) {
        window.scrollTo(0, offset);
    }

    render() {
        const allShows = this.props.shows;
        const today = moment();
        const upcomingShows = allShows.filter((x) => {
            return moment(x.date, 'MM/DD/YYYY').isAfter(today);
        });
        return (
            <div>
                <PageHeaderFull image={require('../../assets/images/gf_1.jpg')} photographer={"Jay Hubert"}>
                    <div className={styles.headerContainer}>
                        <h1>Coyote</h1>
                        <GFButton text={"New Album Released Nov 3, 2023"} icon={<Icon type="down" />} onClick={() => { this.scrollToContent(this.contentRef.current.offsetTop) }}></GFButton>
                    </div>
                </PageHeaderFull>

                <div className={styles.bio} ref={this.contentRef}>
                    <div>
                        <div style={{ marginBottom: '1.5rem' }}>
                            <h2 style={{ fontSize: '1.5rem' }}>New Album, "Coyote"</h2>
                            <h3>Released Nov 3, 2023</h3>
                            <a
                                href="https://open.spotify.com/album/0VqAHei2NPvSP0H221mIkI?si=p_909fXkQnGHo0pVA1hbRA"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    fontSize: '1.3rem',
                                    textDecoration: 'underline',
                                }}
                            >
                                Listen on Spotify
                            </a>
                        </div>
                        <div style={{ marginBottom: '1.5rem' }}>
                            <h2 style={{ fontSize: '1.5rem' }}>New Single, "Passenger"</h2>
                            <h3>Released Oct 13, 2023</h3>
                            <a
                                href="https://open.spotify.com/album/6duiaBChcjN1TIyy2EGAfg?si=YBrPG7x7Ra6qHh4kuLR0_A"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    fontSize: '1.3rem',
                                    textDecoration: 'underline',
                                }}
                            >
                                Listen on Spotify
                            </a>
                        </div>

                        <div style={{ marginBottom: '1.5rem' }}>
                            <h2 style={{ fontSize: '1.5rem' }}>New Single, "Full Pull"</h2>
                            <h3>Released June 16, 2023</h3>
                            <a
                                href="https://open.spotify.com/album/6GJL17HwJv7S6VpcTRcY1B?si=FxEbXVYuQsipE0a3fAoUVg"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    fontSize: '1.3rem',
                                    textDecoration: 'underline',
                                }}
                            >
                                Listen on Spotify
                            </a>
                        </div>

                        <div style={{ marginBottom: '1.5rem' }}>
                            <h2 style={{ fontSize: '1.5rem' }}>New Single, "Coyote (Living Free)"</h2>
                            <h3>Released Oct 30, 2020</h3>
                            <a
                                href="https://open.spotify.com/album/7EH75dEKFYD666W999Fuxb?si=LGQppaTFQlWq6ONYkUrRAw"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    fontSize: '1.3rem',
                                    textDecoration: 'underline',
                                }}
                            >
                                Listen on Spotify
                            </a>
                        </div>
                    </div>
                    <img
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: 500,
                            maxHeight: 500,
                            marginBottom: '1rem'
                        }}
                        src="/poster-thumb.jpg"
                        alt="Good Field Album Release Show Poster"
                    />
                </div>

                <div className={styles.bio}>
                    <div className={styles.imageContainer}>
                        <img src={require('../../assets/images/gf_show.jpg')} alt="Good Field Live" />
                    </div>
                    <div className={styles.textContainer}>
                        <p>“We don’t want to be famous, we just wanna stay right here. And that’s what makes us happy.” Sure, it’s a damned lie, but one told with a convincing poker face at the outset of “Ordinary People.”</p>
                        <p>On their third full-length release, though they’ll dismiss the first two as shoddy little home recordings, Good Field sounds like a band that’s found a comfortable number of beats per minute in which to weave their warm- toned, melodic pop with fuzzy guitar hooks. Confidence looks good on a band that does far too little of its own bell-ringing for any promoter’s taste. It doesn’t hurt when you have Jim Eno (Spoon) at the sound board and James Petralli (White Denim) on hand to curate some of the rangy noise creatures hatched on the creative retreats where the band claims to do the bulk of its writing.</p>
                        <p>The creative process for Surface Tension was unique in the fact that it was written almost entirely on these retreats to remote cabins and ranches in West Texas. There’s not a hint of irony or rehashed hippie new ageism with this though, no yoga mats or hot stone massages. Plenty of armadillos and San Saba sunsets, mixed with all-nighter recording sessions. All this to capture a simple melodic idea or groove to warehouse for later refinement.</p>
                        <p>The forthcoming album, Surface Tension, has as much sonic DNA in common with The Animals or Yo La Tengo as it does The War on Drugs or Deerhunter. In the way that it features simple, deep-voiced compositions with mostly live tracked rhythm sections (quarterbacked by Justin Douglas), the record rounds the nostalgic bases, but with no winks to belie the passing gestures of Tom Petty.</p>
                    </div>

                </div>

                {/* <div className={styles.bottomContent}>
                    <div className={styles.listen} ref={this.contentRef}>
                        <h1>New Album - Coyote</h1>
                        <span className={styles.showDate}>Coming 2020</span>
                    </div>
                    <div className={styles.shows}>
                        <h1>Upcoming Shows</h1>
                        {upcomingShows.map((show, i) => {
                            return (
                                <div className={styles.show} key={i}>
                                    <span className={styles.showDate}>{show.date}</span>
                                    <span className={styles.showVenue}>{show.venue}</span>
                                    <span className={styles.showCity}>{show.city}</span>
                                    <span className={styles.showDetails}>{show.details}</span>
                                </div>
                            )
                        })}
                    </div>
                </div> */}
            </div >
        )
    }
}
