import React from 'react';
import styles from './TopNavDesktop.module.css';
import { Link } from 'react-router-dom';

type Props = {};
type State = {};
export default class TopNavDesktop extends React.Component<Props, State> {

    render() {
        return (
            <div className={styles.container}>
                <h1 className={styles.siteTitle}>Good Field</h1>
                <div className={styles.links}>
                    <Link to={'/'}>Home</Link>
                    <Link to={'/shows'}>Shows</Link>
                    <Link to={'/music'}>Music</Link>
                    <Link to={'/videos'}>Videos</Link>
                    <a href="https://goodfieldband.square.site">Store</a>
                    <Link to={'/contact'}>Contact</Link>
                </div>

            </div>
        )
    }
}
