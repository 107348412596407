import React from 'react';
import { Affix } from 'antd';
import styles from './PageHeader.module.css';

type Props = {
    image: string;
    children?: any;
    backgroundPositionX?: string;
    backgroundPositionY?: string;
    photographer?: string;
}
export const PageHeader: React.FC<Props> = (props: Props) => {
    const headerStyle = {
        backgroundImage: `url('${props.image}')`,
        backgroundPositioX: props.backgroundPositionX || 'center',
        backgroundPositionY: props.backgroundPositionY || '0',
    };
    return (
        <div style={headerStyle} className={styles.container}>
            {/* <Affix offsetTop={70}> */}
            <div className={styles.content}>
                {props.children}
            </div>
            {props.photographer && <div className={styles.photographer}>
                Photographer: {props.photographer}
            </div>}
            {/* </Affix> */}
        </div>
    )
}