import React from 'react';
import TopNavMobile from './TopNavMobile/TopNavMobile';
import TopNavDesktop from './TopNavDesktop/TopNavDesktop';
import styles from './TopNav.module.css';

type Props = {};
type State = {
    isDesktop: boolean;
};
const mql = window.matchMedia(`(min-width: 800px)`);
export default class TopNav extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isDesktop: mql.matches,
        };

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    }

    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    mediaQueryChanged() {
        this.setState({ isDesktop: mql.matches });
    }

    render() {
        return (
            <div className={styles.container}>
                {!this.state.isDesktop && <TopNavMobile />}
                {this.state.isDesktop && <TopNavDesktop />}
            </div>
        )
    }
}