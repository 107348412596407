import React from 'react';
import styles from './Shows.module.css';
import ShowsDesktop from './ShowsDesktop/ShowsDesktop';
import ShowsMobile from './ShowsMobile/ShowsMobile';
import { Show } from './interfaces';
import { PageHeader } from '../../components/PageHeader/PageHeader';
// import { PageHeader } from '../PageHeaderFull/PageHeaderFull';

type Props = {
  shows: Show[];
};
type State = {
  isDesktop: boolean;
};

const mql = window.matchMedia(`(min-width: 800px)`);

export default class Shows extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      isDesktop: mql.matches,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  mediaQueryChanged() {
    this.setState({ isDesktop: mql.matches });
  }

  render() {
    return (
      <div className={styles.container}>
        <PageHeader image={require('../../assets/images/gf_3.jpg')} backgroundPositionY={'20px'} photographer={"Ashley Price"}>
          <h1>Shows</h1>
        </PageHeader>
        <div className={styles.content}>
          {!this.state.isDesktop && <ShowsMobile shows={this.props.shows} />}
          {this.state.isDesktop && <ShowsDesktop shows={this.props.shows} />}
        </div>
      </div>

    )
  }
}