import React from 'react';
import styles from './GFButton.module.css';

type Props = {
    text: string;
    icon?: any;
    onClick?(): void;
};
export const GFButton: React.FC<Props> = (props: Props) => {

    return (
        <button onClick={props.onClick} className={styles.container}>
            {props.text}
            {props.icon && <div className={styles.icon}>{props.icon}</div>}
        </button>
    );

}
