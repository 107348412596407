import React from 'react';
import styles from './Contact.module.css';
// import { PageHeader } from '../../components/PageHeaderFull/PageHeaderFull';

type Props = {};
export const Contact: React.FC<Props> = (props: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <a href="mailto:goodfieldband@gmail.com">goodfieldband@gmail.com</a>
            </div>
            <div className={styles.photographer}>
                Photographer: Jay Hubert
            </div>
        </div>
    )
}