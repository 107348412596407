import React, { CSSProperties } from 'react';
import styles from './TopNavMobile.module.css';
import { Link } from 'react-router-dom';
// @ts-ignore
import HamburgerMenu from 'react-hamburger-menu';
import Sidebar from "react-sidebar";

type Props = {};
type State = {
    isOpen: boolean;
};
export default class TopNavMobile extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    private handleClick() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    private closeMenu() {
        this.setState({ isOpen: false });
    }

    render() {
        const sideBarStyle = {
            transform: this.state.isOpen ? 'translateX(0)' : 'translateX(-550px)'
        } as CSSProperties;
        return (
            <div>
                <div className={styles.sidebarContainer} style={sideBarStyle}>
                    <Link className={styles.link} onClick={this.closeMenu} to={'/'}>Home</Link>
                    <Link className={styles.link} onClick={this.closeMenu} to={'/shows'}>Shows</Link>
                    <Link className={styles.link} onClick={this.closeMenu} to={'/music'}>Music</Link>
                    <Link className={styles.link} onClick={this.closeMenu} to={'/videos'}>Videos</Link>
                    <a className={styles.link} href="https://goodfieldband.square.site">Store</a>
                    <Link className={styles.link} onClick={this.closeMenu} to={'/contact'}>Contact</Link>
                </div>

                <div className={styles.topBar}>
                    <Link to={"/"}><h1 className={styles.siteTitle}>Good Field</h1></Link>
                    <div className={styles.menuIcon}>
                        <HamburgerMenu
                            isOpen={this.state.isOpen}
                            menuClicked={this.handleClick}
                            width={24}
                            height={22}
                            strokeWidth={1}
                            rotate={0}
                            color='#fff'
                            borderRadius={0}
                            animationDuration={0.5}
                        />
                    </div>
                </div>
            </div>


            // <Sidebar
            //     sidebar={
            //         (<div className={styles.links}>
            //             <Link onClick={this.closeMenu} to={'/'}>Home</Link>
            //             <Link onClick={this.closeMenu} to={'/shows'}>Shows</Link>
            //             <Link onClick={this.closeMenu} to={'/music'}>Music</Link>
            //             <Link onClick={this.closeMenu} to={'/videos'}>Videos</Link>
            //             <Link onClick={this.closeMenu} to={'/store'}>Store</Link>
            //             <Link onClick={this.closeMenu} to={'/contact'}>Contact</Link>
            //         </div>)
            //     }
            //     open={this.state.isOpen}
            //     onSetOpen={this.handleClick}
            //     styles={{ sidebar: sideBarStyle, root: { position: 'fixed', overflow: 'scroll' } }}
            // >
            //     <div className={styles.topBar}>
            //         <Link to={"/"}><h1 className={styles.siteTitle}>Good Field</h1></Link>
            //         <div className={styles.menuIcon}>
            //             <HamburgerMenu
            //                 isOpen={this.state.isOpen}
            //                 menuClicked={this.handleClick}
            //                 width={24}
            //                 height={22}
            //                 strokeWidth={1}
            //                 rotate={0}
            //                 color='#fff'
            //                 borderRadius={0}
            //                 animationDuration={0.5}
            //             />
            //         </div>
            //     </div>

            // </Sidebar>
        )
    }
}
