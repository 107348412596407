import React from 'react';
import styles from './Music.module.css';
import { PageHeader } from '../../components/PageHeader/PageHeader';

type Props = {};
type State = {};
export default class Music extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div className={styles.container}>
                <PageHeader image={require('../../assets/images/gf_4.jpg')} photographer={"Jay Hubert"}>
                    <h1>Music</h1>
                </PageHeader>
                <div className={styles.content}>
                    <div className={styles.playlist}>
                        <div className={styles.playlistHeader}>
                            <img src={require('../../assets/images/surface_tension.jpg')} alt="Surface Tension" />
                            <div>
                                <h1>Surface Tension</h1>
                                <h3>2018</h3>
                            </div>
                        </div>
                        <iframe src="https://open.spotify.com/embed/album/7Gfqr08XT3IsubYeCmeB6K" width="300" height="380" frameBorder="0" allow="encrypted-media"></iframe>
                    </div>
                    <div className={styles.playlist}>
                        <div className={styles.playlistHeader}>
                            <img src={require('../../assets/images/future_me.jpg')} alt="Future Me" />
                            <div>
                                <h1>Future Me</h1>
                                <h3>2015</h3>
                            </div>
                        </div>

                        <iframe src="https://open.spotify.com/embed/album/6EEhOlZ1kPhZ0DaNoINnkw" width="300" height="380" frameBorder="0" allow="encrypted-media"></iframe>
                    </div>
                    <div className={styles.playlist}>
                        <div className={styles.playlistHeader}>
                            <img src={require('../../assets/images/debut_album.png')} alt="Good Field Debut Album" />
                            <div>
                                <h1>Debut Album</h1>
                                <h3>2012</h3>
                            </div>

                        </div>

                        <iframe src="https://open.spotify.com/embed/album/3K9bccd55LTtxIRSSVKmEE" width="300" height="380" frameBorder="0" allow="encrypted-media"></iframe>
                    </div>
                </div>
            </div>
        )
    }
}