import React from 'react';
import styles from './ShowsMobile.module.css';
import { Show } from '../interfaces';

type Props = {
    shows: Show[];
};
type State = {};
export default class ShowsMobile extends React.Component<Props, State> {

    render() {
        const showsDisplay = this.props.shows.map((show, i) => {
            return (
                <div key={i} className={styles.container}>
                    <span className={styles.date}>{show.date}</span>
                    <span className={styles.venue}>{show.venue}</span>
                    <span className={styles.time}>{show.time}</span>
                    <span className={styles.city}>{show.city}</span>
                    <span className={styles.details}>{show.details}</span>

                </div>
            )
        })
        return (
            <div className={styles.container}>
                {showsDisplay}
            </div>
        )
    }
}