import React from 'react';
import styles from './App.module.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Shows from './pages/Shows/Shows';
import TopNav from './components/TopNav/TopNav';
import data from './data/shows.json';
import { Contact } from './pages/Contact/Contact';
import Store from './pages/Store/Store';
import Music from './pages/Music/Music';
import Videos from './pages/Videos/Videos';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <TopNav />
      <div className={styles.container}>
        <Route
          exact
          path='/'
          render={(props) => <Home {...props} shows={data} />}
        />
        <Route
          path='/shows'
          render={(props) => <Shows {...props} shows={data} />}
        />
        <Route path="/contact" component={Contact} />
        <Route path="/music" component={Music} />
        <Route path="/videos" component={Videos} />
        {/* <Route path="/store" component={Store} /> */}
      </div>

    </BrowserRouter>

  );
}

export default App;
